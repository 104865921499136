<script setup lang="ts">
import { useCarousel } from "./useCarousel";
import { twMerge, type ClassNameValue } from "tailwind-merge";

defineOptions({ inheritAttrs: false });

const props = defineProps<{ class?: ClassNameValue }>();

const { carouselRef, orientation } = useCarousel();
</script>

<template>
	<div ref="carouselRef" class="overflow-hidden">
		<div
			:class="
				twMerge(
					'flex',
					orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col',
					props.class,
				)
			"
			v-bind="$attrs"
		>
			<slot />
		</div>
	</div>
</template>
