<script setup lang="ts">
import { twMerge, type ClassNameValue } from "tailwind-merge";
import { useCarousel } from "./useCarousel";
import ChevronUpIcon from "@/icons/chevron-up.svg?component";

const props = defineProps<{ class?: ClassNameValue }>();

const { canScrollNext, scrollNext } = useCarousel();
</script>

<template>
	<button
		:disabled="!canScrollNext"
		:class="
			twMerge(
				'flex size-12 touch-manipulation items-center justify-center rounded-full border border-zinc-300 p-0 transition-colors ease-out hover:border-zinc-100 hover:bg-zinc-100 disabled:cursor-not-allowed disabled:opacity-50',
				props.class,
			)
		"
		:variant="undefined"
		@click="scrollNext"
	>
		<slot>
			<ChevronUpIcon
				class="-mr-0.5 size-8 rotate-90 stroke-[2] text-zinc-500"
			/>
		</slot>
	</button>
</template>
