<script setup lang="ts">
import type { PagingObject } from "@/server/utils";
import type {
	PublicConference,
	UserConference,
} from "@/server/conferences/shared";
import { computed, ref } from "vue";
import { onMounted } from "vue";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@/components/ui/carousel";
import PopularConferencesCarouselItem from "@/components/popular-conferences-carousel/PopularConferencesCarouselItem.vue";
import { queryOptions, useQuery, useQueryClient } from "@tanstack/vue-query";
import { publicConferencesPageQuery } from "@/services/conferences.client";
import { ConferenceCard } from "./conference-card";
import ChevronUpIcon from "@/icons/chevron-up.svg?component";
import { useGeoCoordinates } from "@/services/geolocation";

const props = defineProps<{
	islandId: number;
	conferencesPage: PagingObject<PublicConference | UserConference>;
	coordinates: { latitude: number; longitude: number } | null;
}>();

const { coords } = useGeoCoordinates(props.coordinates);

const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});

const queryClient = useQueryClient();
if (import.meta.env.SSR) {
	queryClient.setQueriesData(
		publicConferencesPageQuery({
			page: props.conferencesPage.currentPage,
			pageSize: props.conferencesPage.items.length,
			type: coords.value ? "OFFLINE" : undefined,
			nearLatitude: coords.value?.latitude,
			nearLongitude: coords.value?.longitude,
		}),
		props.conferencesPage,
	);
}

const { data: queryData } = useQuery(
	computed(() =>
		queryOptions({
			...publicConferencesPageQuery({
				page: props.conferencesPage.currentPage,
				pageSize: props.conferencesPage.items.length,
				type: coords.value ? "OFFLINE" : undefined,
				nearLatitude: coords.value?.latitude,
				nearLongitude: coords.value?.longitude,
			}),
			enabled: import.meta.env.SSR === false,
		}),
	),
);

const conferencesPage = computed(() => {
	return import.meta.env.SSR
		? props.conferencesPage
		: queryData.value || props.conferencesPage;
});
</script>

<template>
	<section class="relative border-b border-zinc-200 px-6 py-12 md:px-10">
		<Carousel
			class="container relative flex w-full flex-col"
			:opts="{ align: 'start' }"
		>
			<div class="mb-6 flex justify-between">
				<h2 class="text-3xl font-bold tracking-tight md:text-4xl">
					Medical Conferences
				</h2>
				<div class="flex gap-2">
					<CarouselPrevious />
					<CarouselNext />
				</div>
			</div>
			<div>
				<CarouselContent>
					<CarouselItem
						v-for="conference in conferencesPage!.items"
						:key="conference.id"
						class="basis-full pl-5 md:basis-[55%]"
					>
						<PopularConferencesCarouselItem :conference="conference" />
					</CarouselItem>
				</CarouselContent>
			</div>
		</Carousel>
	</section>
	<section class="relative my-12 px-6 md:px-10">
		<div class="container">
			<div class="flex flex-col gap-6">
				<a
					class="group flex items-center underline-offset-2 hover:underline"
					href="/conferences"
				>
					<h3 class="text-2xl font-bold">Nearby conferences</h3>
					<ChevronUpIcon
						class="size-6 rotate-90 stroke-2 text-zinc-900 transition-transform group-hover:translate-x-1"
					/>
				</a>
				<ul
					class="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"
				>
					<ConferenceCard
						v-for="conference in conferencesPage!.items"
						:key="conference.id"
						:conference="conference"
					/>
				</ul>
			</div>
		</div>
	</section>
</template>
