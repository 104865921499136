<script setup lang="ts">
import { twMerge, type ClassNameValue } from "tailwind-merge";
import { useCarousel } from "./useCarousel";

const props = defineProps<{ class?: ClassNameValue }>();

const { orientation } = useCarousel();
</script>

<template>
	<div
		role="group"
		aria-roledescription="slide"
		:class="
			twMerge(
				'min-w-0 shrink-0 grow-0 basis-full',
				orientation === 'horizontal' ? 'pl-4' : 'pt-4',
				props.class,
			)
		"
	>
		<slot />
	</div>
</template>
